import React from 'react'
import './home-header.css'

//Components
import HomePhone from './home-phone-image'
import { Mixpanel } from './mixpanel'
import Button from './button'

//NPM
import MobileStoreButton from 'react-mobile-store-button'
import styled from 'styled-components'
import StoresButton from './MobileStoreButton'
import VideoCover from 'react-video-cover'

const iosUrl = 'https://itunes.apple.com/tt/app/wheredpump/id1125892248?mt=8'
const androidUrl =
  'https://play.google.com/store/apps/details?id=com.wheredpump.wheredpump.wheredpump&hl=en'

const videoOptions = {
  src: require("../videos/Preview.mp4"),
  autoPlay: true,
  muted: true,
  loop: true,
  playsInline: true
};
const ShowPhone = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`
const VideoContainer = styled.div`
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  border-radius: 7px;
  overflow: hidden;
  min-height: 450px;
  width: min-content;
  transition: 0.3s ease;

  @media (max-width: 600px) {
    display: none;
  }
  
`;
class HomeHeader extends React.Component {


  state = {
    showVideo: false
  }

  componentDidMount() {
    Mixpanel.track('Land on homepage')
    if (typeof window !== "undefined") {
      let vw = window.innerWidth
      if (vw >= 600)
        this.setState({ showVideo: true })
    }
  }

  render() {
    const { showVideo } = this.state
    return (
      <div className="HomeHeader">
        <div className="Intro">
          <div>
            <h1>Find the best events in Trinidad & Tobago. Get tickets in seconds.</h1>
            {/* <p>
              Browse events, purchase your own tickets and get listed to your favourite clubs
              − all from one app.
            </p> */}
            <a target="_blank" href="http://onelink.to/4kj32j"><Button title="Get the app" /></a>
          </div>
          {showVideo &&
            <ShowPhone>
              <VideoContainer>
                <VideoCover
                  videoOptions={videoOptions}
                  style={{ width: '250px', height: 'auto' }}
                />
              </VideoContainer>
              {/* <HomePhone /> */}
            </ShowPhone>
          }
        </div>
      </div>
    )
  }
}

export default HomeHeader
