import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

//Components
import HomeHeader from '../components/home-header'
import AddEventCallout from '../components/add-event-callout'
import UpcomingEventsLimited from '../components/upcoming-events-limited'
import HomeAnnouncement from '../components/home-announcement'
import Footer from '../components/footer'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description="Find the hottest events in Trinidad and Tobago with ease. Get rewarded for pumpin' with us! Download the app now."
      keywords={[
        `trinidad`,
        `tobago`,
        `carnival`,
        `calendar`,
        `events`,
        `fetes`,
        `2019`,
        `2020`,
        `2021`,
        `wheredpump`,
        `schedule`,
        `upcoming`,
        `ticketfederation`,
        `tickets`,
        `lime`,
        `islandetickets`,
        `mobile`,
        `tickets`,
        `club`,
        `guestlist`,
      ]}
    />
    <HomeHeader />
    <AddEventCallout />
    <UpcomingEventsLimited />
    {/* <HomeAnnouncement /> */}
    {/* <Footer /> */}
  </Layout>
)

export default IndexPage
